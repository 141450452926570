import { createApi } from '@reduxjs/toolkit/query/react';
import { restBaseQuery } from '../../configuration/baseQuery';
import { appConfiguration } from '../../configuration/appConfiguration';

export const vehiclesApiSlice = createApi({
  reducerPath: 'vehiclesApi',
  baseQuery: restBaseQuery(appConfiguration.simplepay.api.url),
  endpoints: () => ({}),
});

export const {
  reducer: vehicleReducer,
  reducerPath: vehicleReducerPath,
  middleware: vehicleMiddleware,
} = vehiclesApiSlice;
