import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import React from 'react';
import { CustomFormattedMessage } from '../../../../../i18n/i18n';

type TransactionTableFilterDialogFooterProps = {
  onClose: () => void;
  onSave: () => void;
};

export const TransactionTableFilterDialogFooter: React.FC<
  TransactionTableFilterDialogFooterProps
> = ({ onClose, onSave }) => {
  return (
    <>
      <Button
        bsStyle={'default'}
        type={'button'}
        className={'margin-right-10'}
        onClick={onClose}
      >
        <CustomFormattedMessage id={'common.action.cancel'} />
      </Button>
      <Button
        bsStyle={'primary'}
        type={'button'}
        onClick={onSave}
      >
        <CustomFormattedMessage id={'common.action.apply'} />
      </Button>
    </>
  );
};
