import React from 'react';
import classNames from 'classnames';
import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import {
  ServicePartner,
  ServiceType,
  TransactionState,
} from '../../../../../codegen/transactions';
import {
  SERVICE_PARTNER_TO_TRANSLATION_KEY,
  SERVICE_TO_TRANSLATION_KEY,
  TRANSACTION_STATE_TO_TRANSLATION_KEY,
} from '../../../../../i18n/enumMappings';
import {
  CustomFormattedMessage,
  useCustomIntl,
} from '../../../../../i18n/i18n';

const displayedTransactionStates = [
  TransactionState.Completed,
  TransactionState.Delivered,
  TransactionState.InProgress,
  TransactionState.Canceled,
  TransactionState.Failed,
];

const displayedServicePartners = [
  ServicePartner.Uta,
  ServicePartner.Travis,
  ServicePartner.Mobile24,
];

const displayedServiceTypes = [
  ServiceType.Fueling,
  ServiceType.Parking,
  ServiceType.Washing,
  ServiceType.Breakdown,
];

export const TransactionTableFilterDialogBody: React.FC = () => {
  const intl = useCustomIntl();

  return (
    <div className={classNames('display-grid', 'grid-cols-2')}>
      <div>
        <h4 className={'margin-bottom-20'}>
          <CustomFormattedMessage id={'common.transaction.state'} />
        </h4>
        {displayedTransactionStates.map((state, index, self) => {
          const isLastElement = index === self.length - 1;
          const marginBottom = isLastElement ? 25 : 10;

          return (
            <Checkbox
              key={state}
              data-testid={`transaction-table-filter-dialog-state-${state}`}
              className={`margin-bottom-${marginBottom}`}
              label={intl.formatMessage({
                id: TRANSACTION_STATE_TO_TRANSLATION_KEY[state],
              })}
            />
          );
        })}
        <h4 className={'margin-bottom-20'}>
          <CustomFormattedMessage id={'common.servicePartner'} />
        </h4>
        {displayedServicePartners.map((servicePartner, index, self) => {
          const isLastElement = index === self.length - 1;
          const marginBottom = isLastElement ? 25 : 10;

          return (
            <Checkbox
              key={servicePartner}
              data-testid={`transaction-table-filter-dialog-service-partner-${servicePartner}`}
              className={`margin-bottom-${marginBottom}`}
              label={intl.formatMessage({
                id: SERVICE_PARTNER_TO_TRANSLATION_KEY[servicePartner],
              })}
            />
          );
        })}
      </div>
      <div>
        <h4 className={'margin-bottom-20'}>
          <CustomFormattedMessage id={'common.serviceType'} />
        </h4>
        {displayedServiceTypes.map((serviceType, index, self) => {
          const isLastElement = index === self.length - 1;
          const marginBottom = isLastElement ? 25 : 10;

          return (
            <Checkbox
              key={serviceType}
              data-testid={`transaction-table-filter-dialog-service-type-${serviceType}`}
              className={`margin-bottom-${marginBottom}`}
              label={intl.formatMessage({
                id: SERVICE_TO_TRANSLATION_KEY[serviceType],
              })}
            />
          );
        })}
      </div>
    </div>
  );
};
