import { createApi } from '@reduxjs/toolkit/query/react';
import { restBaseQuery } from '../../configuration/baseQuery';
import { appConfiguration } from '../../configuration/appConfiguration';

export const transactionsApiSlice = createApi({
  reducerPath: 'transactionsApi',
  baseQuery: restBaseQuery(appConfiguration.simplepay.api.url),
  endpoints: () => ({}),
});

export const {
  reducer: transactionsReducer,
  reducerPath: transactionsReducerPath,
  middleware: transactionsMiddleware,
} = transactionsApiSlice;
