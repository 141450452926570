import { RowData, Table } from '../../../../components/table/Table';
import { Pagination } from '../../../../components/table/Pagination';
import React, { useEffect, useMemo, useState } from 'react';
import {
  BOOKING_TABLE_PAGE_SIZE,
  getBookingsPage,
  setPage,
  useTableFilteredBookings,
} from '../../../../state/bookings/bookingsFilterSlice';
import { sortByProperty } from '@rio-cloud/rio-uikit/lib/es/SortUtils';
import { useAppDispatch, useAppSelector } from '../../../../state/hooks';
import { TableColumn } from '../../../../components/table/TableRow';
import { VehicleRepresentation } from '../../../../components/dataRepresentation/VehicleRepresentation';
import { ServiceRepresentation } from '../../../../components/dataRepresentation/ServiceRepresentation';
import { CustomFormattedDate, useCustomIntl } from '../../../../i18n/i18n';
import { MonetaryValue } from '../../../../components/MonetaryValue';
import { BookingStatusLabel } from '../BookingStatusLabel';
import { ServiceType, VehicleType } from '../../../../codegen/vehicles';
import { BookingStatus } from '../../../../codegen/bookings';
import { BookingWithVehicle, SortDirection } from '../../../../models';
import { getSelectedBooking, selectBooking } from '../../state/bookingsUiSlice';
import { appConfiguration } from '../../../../configuration/appConfiguration';

type BookingRow = {
  vehicleName?: string;
  vehicleType?: VehicleType;
  service: ServiceType;
  arrival: string;
  estimatedTotalPrice: number;
  currency: string;
  status: BookingStatus;
  driverName: string;
};

type SortingState = {
  sortBy?: keyof BookingRow;
  sortDirection: SortDirection;
};

type BookingsTableProps = {
  maxTableHeight: string;
  tableRef: React.RefObject<HTMLDivElement>;
};

export const BookingsTable = ({
  maxTableHeight,
  tableRef,
}: BookingsTableProps) => {
  const intl = useCustomIntl();
  const dispatch = useAppDispatch();

  const { filteredBookings, totalBookings, isFetching, findBookingWithId } =
    useTableFilteredBookings();
  const page = useAppSelector(getBookingsPage);
  const selectedBooking = useAppSelector(getSelectedBooking);

  const [highlightedRow, setHighlightedRow] = useState<string | undefined>();

  useEffect(() => {
    if (selectedBooking) {
      const bookingInMap = findBookingWithId(selectedBooking.id);

      if (bookingInMap) {
        dispatch(selectBooking(bookingInMap));
      }
    }
  }, [filteredBookings]);

  const [sorting, setSorting] = useState<SortingState>({
    sortDirection: SortDirection.ASCENDING,
  });

  const handleBookingClicked = (id: string) => {
    const booking = findBookingWithId(id);
    if (booking) {
      dispatch(selectBooking(booking));
    }
  };

  const handlePageChanged = (pageNumber: number) => {
    dispatch(setPage(pageNumber));
  };

  const handleSortChanged = (
    sortBy: keyof BookingRow,
    sortDirection: SortDirection,
  ) => {
    setSorting((prevSorting) => ({
      ...prevSorting,
      sortBy,
      sortDirection,
    }));
  };

  const columns: TableColumn<BookingRow>[] = [
    {
      id: 'vehicleName',
      label: intl.formatMessage({
        id: 'bookings.bookingsTable.header.vehicle',
      }),
      renderer: (booking: BookingRow) => (
        <VehicleRepresentation
          vehicleType={booking.vehicleType}
          vehicleName={booking.vehicleName}
        />
      ),
    },
    {
      id: 'service',
      label: intl.formatMessage({
        id: 'bookings.bookingsTable.header.service',
      }),
      renderer: (booking: BookingRow) => (
        <ServiceRepresentation serviceType={booking.service} />
      ),
    },
    {
      id: 'arrival',
      label: intl.formatMessage({
        id: 'bookings.bookingsTable.header.arrival',
      }),
      renderer: (booking: BookingRow) => (
        <CustomFormattedDate value={booking.arrival} />
      ),
    },
    {
      id: 'estimatedTotalPrice',
      label: intl.formatMessage({
        id: 'bookings.bookingsTable.header.estimatedTotalPrice',
      }),
      renderer: (booking: BookingRow) => (
        <span className="text-medium">
          {!!booking.estimatedTotalPrice && !!booking.currency && (
            <MonetaryValue
              amount={booking.estimatedTotalPrice}
              currency={booking.currency}
            />
          )}
        </span>
      ),
    },
    {
      id: 'status',
      label: intl.formatMessage({
        id: 'bookings.bookingsTable.header.status',
      }),
      renderer: (booking: BookingRow) => (
        <BookingStatusLabel status={booking.status} />
      ),
    },
    {
      id: 'driverName',
      label: intl.formatMessage({
        id: 'bookings.bookingsTable.header.driverName',
      }),
      renderer: (booking: BookingRow) => <>{booking.driverName}</>,
    },
  ];

  useEffect(() => {
    if (selectedBooking) {
      const bookingInMap = findBookingWithId(selectedBooking.id);

      if (bookingInMap) {
        dispatch(selectBooking(bookingInMap));
      }
    }
  }, [filteredBookings]);

  const rowData: RowData<BookingRow>[] = useMemo(
    () =>
      filteredBookings.map((b: BookingWithVehicle) => ({
        key: b.id,
        enabled: true,
        vehicleName: b.vehicle?.name,
        vehicleType: b.vehicle?.type,
        vehicleGroups: b.vehicle?.groups.map((g) => g.name) ?? [],
        service: b.serviceType,
        arrival: b.arrivalTime,
        estimatedTotalPrice: parseFloat(b.estimatedPrice.value),
        currency: b.estimatedPrice.currency,
        status: b.status,
        driverName: b.driverName,
      })),
    [filteredBookings],
  );

  const sortedRowData = sorting.sortBy
    ? sortByProperty(rowData, sorting.sortBy, sorting.sortDirection)
    : rowData;

  const numberOfPages = Math.ceil(totalBookings / BOOKING_TABLE_PAGE_SIZE);

  const sortingConfig = appConfiguration.iaaFeatures.enabled
    ? {
        sortBy: sorting.sortBy,
        sortDirection: sorting.sortDirection,
        onSortChanged: handleSortChanged,
      }
    : undefined;

  return (
    <>
      <div
        className="table-responsive"
        data-testid="bookings-body-bookings-table"
        id={'bookings-body-bookings-table'}
      >
        <div
          ref={tableRef}
          style={{ maxHeight: maxTableHeight }}
        >
          <Table
            sorting={sortingConfig}
            columns={columns}
            rowData={sortedRowData}
            highlightedRow={highlightedRow}
            onRowClicked={(key) => {
              setHighlightedRow(key);
              handleBookingClicked(key);
            }}
            isLoading={isFetching}
          />
        </div>
      </div>
      <Pagination
        currentPage={page}
        numberOfPages={numberOfPages}
        onPageChanged={handlePageChanged}
      />
    </>
  );
};
