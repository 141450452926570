import { vehiclesApiSlice as api } from '../state/vehicles/vehiclesSlice';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getVehicles: build.query<GetVehiclesApiResponse, GetVehiclesApiArg>({
      query: () => ({ url: `/vehicles` }),
    }),
    getVehicleById: build.query<
      GetVehicleByIdApiResponse,
      GetVehicleByIdApiArg
    >({
      query: (queryArg) => ({ url: `/vehicles/${queryArg.vehicleId}` }),
    }),
    updateServiceActivations: build.mutation<
      UpdateServiceActivationsApiResponse,
      UpdateServiceActivationsApiArg
    >({
      query: (queryArg) => ({
        url: `/vehicles/${queryArg.vehicleId}`,
        method: 'PATCH',
        body: queryArg.updateServiceActivationsRequest,
      }),
    }),
    addPaymentMethod: build.mutation<
      AddPaymentMethodApiResponse,
      AddPaymentMethodApiArg
    >({
      query: (queryArg) => ({
        url: `/vehicles/${queryArg.vehicleId}/paymentMethods`,
        method: 'POST',
        body: queryArg.addPaymentMethodRequest,
      }),
    }),
    removePaymentMethod: build.mutation<
      RemovePaymentMethodApiResponse,
      RemovePaymentMethodApiArg
    >({
      query: (queryArg) => ({
        url: `/vehicles/${queryArg.vehicleId}/paymentMethods/${queryArg.paymentMethodId}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetVehiclesApiResponse = /** status 200 OK */ VehiclesList;
export type GetVehiclesApiArg = void;
export type GetVehicleByIdApiResponse = /** status 200 OK */ Vehicle;
export type GetVehicleByIdApiArg = {
  /** Vehicle ID in context of RIO asset management */
  vehicleId: string;
};
export type UpdateServiceActivationsApiResponse = /** status 200 OK */ Vehicle;
export type UpdateServiceActivationsApiArg = {
  /** Vehicle ID in context of RIO asset management */
  vehicleId: string;
  updateServiceActivationsRequest: UpdateServiceActivationsRequest;
};
export type AddPaymentMethodApiResponse =
  /** status 201 CREATED */ PaymentMethod;
export type AddPaymentMethodApiArg = {
  /** Vehicle ID in context of RIO asset management */
  vehicleId: string;
  addPaymentMethodRequest: AddPaymentMethodRequest;
};
export type RemovePaymentMethodApiResponse = /** status 204 NO CONTENT */ void;
export type RemovePaymentMethodApiArg = {
  /** Vehicle ID in context of RIO asset management */
  vehicleId: string;
  /** Payment method ID in context of a vehicle */
  paymentMethodId: string;
};
export type PaymentMethodExpiryDate = string;
export type PaymentMethod = {
  id: string;
  type: PaymentMethodType;
  paymentPartner: PaymentPartnerType;
  lastFourDigits: string;
  expiry: PaymentMethodExpiryDate;
};
export type PaymentMethodAssignment = {
  /** Reference to `vehicle.paymentMethods[].id`. */
  paymentMethodId: string;
};
export type ServiceActivation = {
  enabled: boolean;
  serviceType: ServiceType;
  paymentMethodAssignments: PaymentMethodAssignment[];
};
export type Vehicle = {
  id: string;
  name: string;
  vin: string;
  type: VehicleType;
  /** Indicates that the vehicle was not activated for SimplePay yet. */
  enabled: boolean;
  group_ids: string[];
  brand: string;
  paymentMethods: PaymentMethod[];
  serviceActivations: ServiceActivation[];
};
export type VehiclesList = {
  items: Vehicle[];
};
export type ErrorResponse = {
  message: string;
  errorCode: ErrorCode;
  fields: string[];
};
export type UpdateServiceActivationsRequest = {
  serviceActivations: ServiceActivation[];
};
export type AddPaymentMethodRequest = {
  type: PaymentMethodType;
  paymentPartner: PaymentPartnerType;
  /** Service Card Number */
  cardNumber: string;
  expiry: PaymentMethodExpiryDate;
  /** Token representing the user at desired paymentPartner. Currently only required if paymentPartner equals UTA_PAYMENT */
  paymentPartnerUserToken?: string;
};
export enum VehicleType {
  Truck = 'TRUCK',
  Bus = 'BUS',
  Van = 'VAN',
  Trailer = 'TRAILER',
  Car = 'CAR',
}
export enum PaymentMethodType {
  FuelCard = 'FUEL_CARD',
}
export enum PaymentPartnerType {
  MockFuelingPayment = 'MOCK_FUELING_PAYMENT',
  UtaPayment = 'UTA_PAYMENT',
}
export enum ServiceType {
  Fueling = 'FUELING',
  Breakdown = 'BREAKDOWN',
  Parking = 'PARKING',
  Washing = 'WASHING',
}
export enum ErrorCode {
  InvalidPathParameters = 'INVALID_PATH_PARAMETERS',
  InvalidQueryParameters = 'INVALID_QUERY_PARAMETERS',
  InvalidPayload = 'INVALID_PAYLOAD',
  InvalidFuelCard = 'INVALID_FUEL_CARD',
  InvalidStatusChange = 'INVALID_STATUS_CHANGE',
  CancelationNotAllowedTransactionAlreadyCanceled = 'CANCELATION_NOT_ALLOWED_TRANSACTION_ALREADY_CANCELED',
  CancelationNotAllowedTransactionNotStarted = 'CANCELATION_NOT_ALLOWED_TRANSACTION_NOT_STARTED',
  CancelationNotAllowedTransactionTransactionFinished = 'CANCELATION_NOT_ALLOWED_TRANSACTION_TRANSACTION_FINISHED',
  PoiNotFound = 'POI_NOT_FOUND',
  OnlinePaymentNotSupported = 'ONLINE_PAYMENT_NOT_SUPPORTED',
  PaymentMethodActive = 'PAYMENT_METHOD_ACTIVE',
  VehicleNotFound = 'VEHICLE_NOT_FOUND',
  VehicleServiceNotActivated = 'VEHICLE_SERVICE_NOT_ACTIVATED',
  PaymentMethodNotFound = 'PAYMENT_METHOD_NOT_FOUND',
  UnknownError = 'UNKNOWN_ERROR',
}
export const {
  useGetVehiclesQuery,
  useGetVehicleByIdQuery,
  useUpdateServiceActivationsMutation,
  useAddPaymentMethodMutation,
  useRemovePaymentMethodMutation,
} = injectedRtkApi;
