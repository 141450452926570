import { Subscription } from './baseQuery';
import { parse } from 'graphql';

export const USED_TRANSACTION_FIELDS = `
        id
        total_amount_gross
        total_amount_net
        currency
        items {
          description
          quantity
          unit
          item_price_gross
          sum_gross
          vat
        }
        service
        service_partner
        state
        created_at
        mileage
        vehicle {
          id
          group_ids
          name
          type
          vin
        }
        payment_details {
          paid_at
          payment_method
          payment_partner
        }
        fueling_details {
          fuel_station_name
          fuel_station_brand
          fuel_station_id
          fuel_pump_number
          fuel_station_address {
            street
            city
            zip_code
            state
            country
          }
        }
`;

export const onTransactionUpdated: Subscription = {
  definition: parse(`
    subscription OnTransactionUpdated($account_id: String!) {
      onTransactionUpdated(account_id: $account_id) {
          ${USED_TRANSACTION_FIELDS}
      }
    }
  `),
};
