import { useMemo } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useAppDispatch, useAppSelector } from '../hooks';
import { getUser } from '../../login/loginSlice';
import {
  enhancedApi,
  GetTransactionsApiArg,
  GetTransactionsApiResponse,
} from '../../codegen/transactions';
import { useGetVehicleGroupsQuery } from '../vehicleGroups/vehicleGroupsSlice';
import { transactionsApiSlice } from './transactionsSlice';
import { DEFAULT_CURRENCY } from './transactionFilterSlice';
import { PartialPick } from '../../models';

const DEFAULT_TRANSACTION_PAGE_SIZE = 1000;

type PaginationProps = 'pageNumber' | 'pageSize';

type GetTransactionsRequestParams = PartialPick<
  GetTransactionsApiArg,
  PaginationProps
>;

export const useTransactions = (request?: GetTransactionsRequestParams) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const transactionsQuery = useGetTransactionsPaginatedQuery(
    request ? request : {},
  );
  const groupsQuery = useGetVehicleGroupsQuery({ user });

  const refetchTransactions = () => {
    groupsQuery.refetch();
    transactionsQuery.refetch();
  };

  const resetApiState = () => {
    dispatch(transactionsApiSlice.util.resetApiState());
  };

  return {
    transactions: transactionsQuery.data?.items || [],
    totalTransactions: transactionsQuery.data?.totalItems || 0,
    isLoading:
      transactionsQuery.isLoading ||
      groupsQuery.isLoading ||
      transactionsQuery.isFetching ||
      groupsQuery.isFetching,
    error: transactionsQuery.error || groupsQuery.error,
    refetchTransactions,
    resetApiState,
  };
};

export const useCurrencyOptions = () => {
  const { transactions } = useTransactions();
  return useMemo(() => {
    const currencies = [
      ...new Set(
        transactions?.filter((x) => !!x.currency).map((x) => x.currency!),
      ),
    ].sort();
    if (currencies.length === 0) {
      currencies.push(DEFAULT_CURRENCY);
    }
    return currencies.map((c) => ({ id: c, label: c }));
  }, [transactions]);
};

const injectedRtkApiEndpoints = enhancedApi.injectEndpoints({
  endpoints: (build) => ({
    getTransactionsPaginated: build.query<
      GetTransactionsApiResponse,
      GetTransactionsRequestParams
    >({
      queryFn: async (queryArg, _queryApi, _extraOptions, fetchWithBQ) => {
        const url = '/transactions';
        /* If the `pageSize` and `pageNumber` are provided, use the standard query approach. */
        if (
          queryArg.pageSize !== undefined &&
          queryArg.pageNumber !== undefined
        ) {
          return fetchWithBQ({
            url,
            params: {
              pageSize: queryArg.pageSize,
              pageNumber: queryArg.pageNumber,
              vehicleId: queryArg.vehicleId,
              vehicleGroupId: queryArg.vehicleGroupId,
              serviceType: queryArg.serviceType,
              currency: queryArg.currency,
              fromCreatedAt: queryArg.fromCreatedAt,
              toCreatedAt: queryArg.toCreatedAt,
            },
          });
          /* If the `pageSize` and `pageNumber` are not provided, trigger multiple paginated queries. */
        } else {
          const aggregatedResponse: GetTransactionsApiResponse = {
            items: [],
            totalItems: 0,
            totalPages: 1,
          };
          let currentPage = 0;
          let totalPages = 1;

          while (currentPage < totalPages) {
            const pageResult = await fetchWithBQ({
              url,
              params: {
                pageSize: DEFAULT_TRANSACTION_PAGE_SIZE,
                pageNumber: currentPage,
                vehicleId: queryArg.vehicleId,
                vehicleGroupId: queryArg.vehicleGroupId,
                serviceType: queryArg.serviceType,
                currency: queryArg.currency,
                fromCreatedAt: queryArg.fromCreatedAt,
                toCreatedAt: queryArg.toCreatedAt,
              },
            });

            if (pageResult.error) {
              return { error: pageResult.error as FetchBaseQueryError };
            }

            const pageData = pageResult.data as GetTransactionsApiResponse;
            aggregatedResponse.items.push(...pageData.items);
            aggregatedResponse.totalItems = aggregatedResponse.items.length;

            if (currentPage === 0) {
              totalPages = pageData.totalPages;
            }

            currentPage++;
          }

          return { data: aggregatedResponse };
        }
      },
    }),
  }),
});

export const { useGetTransactionsPaginatedQuery } = injectedRtkApiEndpoints;
