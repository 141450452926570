import TeaserContainer from '@rio-cloud/rio-uikit/TeaserContainer';
import bookingsImage from '../../assets/images/landingPage/feature-booking.svg';
import transactionsImage from '../../assets/images/landingPage/feature-transactions.svg';
import dashboardImage from '../../assets/images/landingPage/feature-dashboard.svg';
import cardManagementImage from '../../assets/images/landingPage/feature-card-management.png';
import driverAppImage from '../../assets/images/landingPage/driver-app.svg';
import fleetMonitorImage from '../../assets/images/landingPage/fleet-monitor.svg';
import { CustomFormattedMessage } from '../../i18n/i18n';
import { FeatureTeaser } from './FeatureTeaser';
import {
  BOOKINGS_ROUTE,
  DASHBOARD_ROUTE,
  ONBOARDING_ROUTE,
  TRANSACTIONS_ROUTE,
} from '../../routes/routes';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../state/hooks';
import { getLocale } from '../../i18n/localeSlice';
import { appConfiguration } from '../../configuration/appConfiguration';

const LOCALE_GERMAN = 'de-DE';

export const LandingPage = () => {
  const navigate = useNavigate();
  const userLocale = useAppSelector(getLocale);

  const openLinkInNewTab = (link: string) => {
    window.open(link, '_blank');
  };

  const showPartnerTeasers =
    appConfiguration.bookings.enabled || appConfiguration.diverApp.enabled;

  return (
    <>
      <h1 className={'text-center margin-top-0 margin-bottom-25'}>
        <CustomFormattedMessage id={'landing.headline'} />
      </h1>
      <div data-testId={'landing-page-feature-teasers'}>
        <TeaserContainer
          teaserPerRow={appConfiguration.bookings.enabled ? 4 : 3}
          className={'justify-content-center'}
        >
          <FeatureTeaser
            headline={
              <CustomFormattedMessage
                id={'base.header.navigationLinks.onboarding'}
              />
            }
            content={
              <CustomFormattedMessage
                id={'landing.featureTeaser.cardManagement.body'}
              />
            }
            onClick={() => navigate(ONBOARDING_ROUTE)}
            image={cardManagementImage}
          />
          <FeatureTeaser
            headline={
              <CustomFormattedMessage
                id={'base.header.navigationLinks.dashboard'}
              />
            }
            content={
              <CustomFormattedMessage
                id={'landing.featureTeaser.dashboard.body'}
              />
            }
            onClick={() => navigate(DASHBOARD_ROUTE)}
            image={dashboardImage}
          />
          <FeatureTeaser
            headline={
              <CustomFormattedMessage
                id={'base.header.navigationLinks.transactions'}
              />
            }
            content={
              <CustomFormattedMessage
                id={'landing.featureTeaser.transactions.body'}
              />
            }
            onClick={() => navigate(TRANSACTIONS_ROUTE)}
            image={transactionsImage}
          />
          {appConfiguration.bookings.enabled && (
            <FeatureTeaser
              headline={
                <CustomFormattedMessage
                  id={'base.header.navigationLinks.bookings'}
                />
              }
              content={
                <CustomFormattedMessage
                  id={'landing.featureTeaser.bookings.body'}
                />
              }
              onClick={() => navigate(BOOKINGS_ROUTE)}
              image={bookingsImage}
            />
          )}
        </TeaserContainer>
      </div>
      {showPartnerTeasers && (
        <>
          <h2 className={'text-center margin-top-20 margin-bottom-20'}>
            <CustomFormattedMessage
              id={'landing.subHeadline.partnerServices'}
            />
          </h2>
          <div data-testId={'landing-page-partner-teasers'}>
            <TeaserContainer
              teaserPerRow={4}
              className={'justify-content-center'}
            >
              {appConfiguration.bookings.enabled && (
                <FeatureTeaser
                  image={fleetMonitorImage}
                  headline={
                    <CustomFormattedMessage
                      id={'landing.partnerTeaser.fleetMonitor.headline'}
                    />
                  }
                  content={
                    <CustomFormattedMessage
                      id={'landing.partnerTeaser.fleetMonitor.body'}
                    />
                  }
                  onClick={() => {
                    openLinkInNewTab('https://livemonitor.rio.cloud');
                  }}
                />
              )}
              {appConfiguration.diverApp.enabled && (
                <FeatureTeaser
                  image={driverAppImage}
                  headline={
                    <CustomFormattedMessage
                      id={'landing.partnerTeaser.driverApp.headline'}
                    />
                  }
                  content={
                    <CustomFormattedMessage
                      id={'landing.partnerTeaser.driverApp.body'}
                    />
                  }
                  onClick={() => {
                    const link =
                      userLocale === LOCALE_GERMAN
                        ? 'https://www.man.eu/de/de/service/man-driver-app-103744.html'
                        : 'https://www.man.eu/uk/en/service/man-driver-app-103744.html';

                    openLinkInNewTab(link);
                  }}
                />
              )}
            </TeaserContainer>
          </div>
        </>
      )}
    </>
  );
};
