import TableSearch from '@rio-cloud/rio-uikit/lib/es/TableSearch';
import { useCustomIntl } from '../../i18n/i18n';

export const TableToolbarSearch = () => {
  const intl = useCustomIntl();
  const placeholder = intl.formatMessage({
    id: 'common.table.toolbar.searchBarPlaceholder',
  });

  return (
    <div className="table-toolbar-column">
      <TableSearch placeholder={placeholder} />
    </div>
  );
};
