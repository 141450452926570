import ApplicationHeader from '@rio-cloud/rio-uikit/lib/es/ApplicationHeader';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { CustomFormattedMessage } from '../../i18n/i18n';
import { RioAppNavigatorDropdown } from './RioAppNavigatorDropdown';
import { UserMenu } from './UserMenu';
import { SupportMenu } from './SupportMenu';
import { appConfiguration } from '../../configuration/appConfiguration';
import {
  BOOKINGS_ROUTE,
  DASHBOARD_ROUTE,
  ONBOARDING_ROUTE,
  TRANSACTIONS_ROUTE,
} from '../../routes/routes';

export const Header: React.FC = () => {
  const navItems: { key: string; route: JSX.Element }[] = [
    {
      key: 'onboarding',
      route: (
        <NavLink to={ONBOARDING_ROUTE}>
          <CustomFormattedMessage
            id={'base.header.navigationLinks.onboarding'}
          />
        </NavLink>
      ),
    },
    {
      key: 'dashboard',
      route: (
        <NavLink to={DASHBOARD_ROUTE}>
          <CustomFormattedMessage
            id={'base.header.navigationLinks.dashboard'}
          />
        </NavLink>
      ),
    },
    {
      key: 'transactions',
      route: (
        <NavLink to={TRANSACTIONS_ROUTE}>
          <CustomFormattedMessage
            id={'base.header.navigationLinks.transactions'}
          />
        </NavLink>
      ),
    },
  ];

  if (appConfiguration.bookings.enabled) {
    navItems.push({
      key: 'bookings',
      route: appConfiguration.bookings.enabled && (
        <NavLink to={BOOKINGS_ROUTE}>
          <CustomFormattedMessage id={'base.header.navigationLinks.bookings'} />
        </NavLink>
      ),
    });
  }

  return (
    <ApplicationHeader
      label={'MAN SimplePay'}
      appNavigator={<RioAppNavigatorDropdown />}
      homeRoute={<NavLink to={''} />}
      navItems={navItems}
      actionBarItems={[
        <SupportMenu key="support-menu" />,
        <UserMenu key="user-menu" />,
      ]}
    />
  );
};
