import React from 'react';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { useCustomIntl } from '../../../../../i18n/i18n';
import { useAppDispatch, useAppSelector } from '../../../../../state/hooks';
import {
  closeFilterDialog,
  isFilterDialogOpen,
} from '../../../state/transactionsUiSlice';
import { TransactionTableFilterDialogFooter } from './TransactionTableFilterDialogFooter';
import { TransactionTableFilterDialogBody } from './TransactionTableFilterDialogBody';

export const TransactionsTableFilterDialog: React.FC = () => {
  const intl = useCustomIntl();
  const showDialog = useAppSelector(isFilterDialogOpen);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(closeFilterDialog());
  };

  return showDialog ? (
    <Dialog
      aria-label={'transactions-filter'}
      useOverflow
      bsSize={Dialog.SIZE_MD}
      show
      onHide={handleClose}
      title={intl.formatMessage({
        id: 'transactions.transactionsTable.filterDialog.title',
      })}
      body={<TransactionTableFilterDialogBody />}
      footer={
        <TransactionTableFilterDialogFooter
          onClose={handleClose}
          onSave={handleClose}
        />
      }
    />
  ) : (
    <></>
  );
};
