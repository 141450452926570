import React, { useState } from 'react';
import { CustomFormattedDate, useCustomIntl } from '../../../../i18n/i18n';
import { MonetaryValue } from '../../../../components/MonetaryValue';
import { TransactionStateLabel } from '../TransactionStateLabel';
import { TransactionsTableToolbar } from './TransactionsTableToolbar';
import {
  getTransactionPage,
  setPage,
  TRANSACTION_TABLE_PAGE_SIZE,
  useTableFilteredTransactions,
} from '../../../../state/transactions/transactionFilterSlice';
import {
  openSidebar,
  setSidebarTransaction,
} from '../../state/transactionsUiSlice';
import { useAppDispatch, useAppSelector } from '../../../../state/hooks';
import { ServiceRepresentation } from '../../../../components/dataRepresentation/ServiceRepresentation';
import { TableColumn } from '../../../../components/table/TableRow';
import { RowData, Table } from '../../../../components/table/Table';
import { Pagination } from '../../../../components/table/Pagination';
import { useVehicles } from '../../../../state/vehicles/useVehicles';
import { SortDirection, TransactionWithVehicle } from '../../../../models';
import { mapTransactionsToRows, TransactionRow } from './mapTransactionsToRows';
import { VehicleRepresentation } from '../../../../components/dataRepresentation/VehicleRepresentation';
import { sortByProperty } from '@rio-cloud/rio-uikit/lib/es/SortUtils';
import { appConfiguration } from '../../../../configuration/appConfiguration';
import { ServicePartnerRepresentation } from '../../../../components/dataRepresentation/ServicePartnerRepresentation';
import { PaymentMethodRepresentation } from '../../../../components/dataRepresentation/PaymentMethodRepresentation';

type SortingState = {
  sortBy?: keyof TransactionRow;
  sortDirection: SortDirection;
};

export const TransactionTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const intl = useCustomIntl();

  const { filteredTransactions, totalTransactions, isLoading } =
    useTableFilteredTransactions();

  const [highlightedRow, setHighlightedRow] = useState<string | undefined>();
  const [sorting, setSorting] = useState<SortingState>({
    sortDirection: SortDirection.ASCENDING,
  });

  const { vehicles } = useVehicles();

  const page = useAppSelector(getTransactionPage);
  const transactionsVehicleMap: Map<string, TransactionWithVehicle> = new Map();

  const handleTransactionClicked = (id: string) => {
    dispatch(setSidebarTransaction(transactionsVehicleMap.get(id)!));
    dispatch(openSidebar());
  };

  const handleSortChanged = (
    sortBy: keyof TransactionRow,
    sortDirection: SortDirection,
  ) => {
    setSorting((prevSorting) => ({
      ...prevSorting,
      sortBy,
      sortDirection,
    }));
  };

  const handlePageChanged = (pageNumber: number) => {
    dispatch(setPage(pageNumber));
  };

  const columns: TableColumn<TransactionRow>[] = [
    {
      id: 'vehicleName',
      label: intl.formatMessage({
        id: 'transactions.transactionsTable.header.vehicle',
      }),
      renderer: (transaction: TransactionRow) => (
        <VehicleRepresentation
          vehicleType={transaction.vehicleType}
          vehicleName={transaction.vehicleName}
        />
      ),
    },
    {
      id: 'service',
      label: intl.formatMessage({
        id: 'transactions.transactionsTable.header.service',
      }),
      renderer: (transaction: TransactionRow) => (
        <ServiceRepresentation serviceType={transaction.service} />
      ),
    },
    {
      id: 'servicePartner',
      label: intl.formatMessage({
        id: 'common.servicePartner',
      }),
      renderer: (transaction: TransactionRow) => (
        <ServicePartnerRepresentation
          servicePartner={transaction.servicePartner}
        />
      ),
    },
    {
      id: 'createdAt',
      label: intl.formatMessage({
        id: 'transactions.transactionsTable.header.createdAt',
      }),
      renderer: (transaction: TransactionRow) => (
        <CustomFormattedDate value={transaction.createdAt} />
      ),
    },
    {
      id: 'paymentMethod',
      label: intl.formatMessage({
        id: 'transactions.transactionsTable.header.paymentMethod',
      }),
      renderer: (transaction: TransactionRow) => (
        <PaymentMethodRepresentation
          paymentMethod={transaction.paymentMethod}
          paymentPartner={transaction.paymentPartner}
        />
      ),
    },
    {
      id: 'totalPriceGross',
      label: intl.formatMessage({
        id: 'common.transaction.totalPriceGross',
      }),
      renderer: (transaction: TransactionRow) => (
        <span className={'text-medium'}>
          {!!transaction.totalPriceGross && !!transaction.currency && (
            <MonetaryValue
              amount={transaction.totalPriceGross}
              currency={transaction.currency}
            />
          )}
        </span>
      ),
    },
    {
      id: 'status',
      label: intl.formatMessage({
        id: 'transactions.transactionsTable.header.status',
      }),
      renderer: (transaction: TransactionRow) => (
        <TransactionStateLabel state={transaction.status} />
      ),
    },
  ];

  const rowData: RowData<TransactionRow>[] = mapTransactionsToRows(
    filteredTransactions,
    vehicles,
    transactionsVehicleMap,
  );

  const sortedRowData = sorting.sortBy
    ? sortByProperty(rowData, sorting.sortBy, sorting.sortDirection)
    : rowData;

  const numberOfPages = Math.ceil(
    totalTransactions / TRANSACTION_TABLE_PAGE_SIZE,
  );

  const sortingConfig = appConfiguration.iaaFeatures.enabled
    ? {
        sortBy: sorting.sortBy,
        sortDirection: sorting.sortDirection,
        onSortChanged: handleSortChanged,
      }
    : undefined;

  return (
    <div className="display-flex flex-column height-100pct">
      <TransactionsTableToolbar />
      <div
        className="table-responsive overflow-y-auto"
        data-testid="transactions-body-transactions-table"
      >
        <Table
          sorting={sortingConfig}
          columns={columns}
          rowData={sortedRowData}
          highlightedRow={highlightedRow}
          onRowClicked={(key) => {
            setHighlightedRow(key);
            handleTransactionClicked(key);
          }}
          isLoading={isLoading}
        />
      </div>
      <Pagination
        currentPage={page}
        numberOfPages={numberOfPages}
        onPageChanged={handlePageChanged}
      />
    </div>
  );
};
