import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { appConfiguration } from '../../configuration/appConfiguration';
import { TransactionSubscriptionEvent } from '../../state/transactions/dto';

type Props = {
  onClick: (event: TransactionSubscriptionEvent) => void;
};

// TODO JUP-1267: Remove this component once the GraphQL mock supports subscriptions
/* Hidden button for integration testing */
/* Hint: for manual testing, remove the 'display-none' class bellow. */
export const MockTransactionUpdate = ({ onClick }: Props) => {
  const triggerMockTransactionUpdate = () => {
    const event: TransactionSubscriptionEvent = {
      data: {
        onTransactionUpdated: {
          id: '',
        },
      },
    };
    onClick(event);
  };

  if (!appConfiguration.mock.transactionUpdate) {
    return <></>;
  }

  return (
    <div className="position-absolute top-10 right-10pct z-index-max display-none">
      <Button
        data-testid="mock-transaction-update-button"
        className="btn btn-danger"
        onClick={triggerMockTransactionUpdate}
      >
        Trigger mock transaction update
      </Button>
    </div>
  );
};
