import TableToolbar from '@rio-cloud/rio-uikit/lib/es/TableToolbar';
import React from 'react';
import { CustomFormattedMessage, useCustomIntl } from '../../../../i18n/i18n';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import DatePicker from '@rio-cloud/rio-uikit/lib/es/DatePicker';
import {
  getFromFilter,
  getPeriodFilter,
  getToFilter,
  getTransactionCurrenciesFilter,
  resetTransactionsFilters,
  setFrom,
  setPeriod,
  setTo,
  setTransactionsCurrencies,
  useTableFilteredTransactions,
} from '../../../../state/transactions/transactionFilterSlice';
import { useAppDispatch, useAppSelector } from '../../../../state/hooks';
import moment from 'moment/moment';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import Multiselect from '@rio-cloud/rio-uikit/lib/es/Multiselect';
import { useCurrencyOptions } from '../../../../state/transactions/useTransactions';
import { CsvExportButton } from './CsvExportButton';
import { useTransactionUpdateState } from '../../../../state/transactions/transactionUpdateSlice';
import {
  usePeriodSelectOptions,
  parsePeriod,
} from '../../../../usePeriodSelectOptions';
import { openFilterDialog } from '../../state/transactionsUiSlice';
import { appConfiguration } from '../../../../configuration/appConfiguration';
import { TableToolbarSearch } from '../../../../components/table/TableToolbarSearch';

export const TransactionsTableToolbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const intl = useCustomIntl();
  const { transactionFilterPeriods } = usePeriodSelectOptions();

  const currencies = useAppSelector(getTransactionCurrenciesFilter);
  const from = useAppSelector(getFromFilter);
  const to = useAppSelector(getToFilter);
  const period = useAppSelector(getPeriodFilter);

  const currencyOptions = useCurrencyOptions().map((c) => {
    return {
      ...c,
      selected: currencies.includes(c.id),
    };
  });

  const { hasTransactionUpdate, reset: resetTransactionUpdateState } =
    useTransactionUpdateState();

  const { resetApiState } = useTableFilteredTransactions();

  const handleRefreshTransactions = () => {
    resetApiState();
    resetTransactionUpdateState();
  };

  const handleDatePicked = (callback: (millis: number) => any) => {
    return (value: moment.Moment, valid: boolean) => {
      if (valid) {
        const millis = value.valueOf();
        dispatch(callback(millis));
      }
    };
  };

  return (
    <TableToolbar>
      <div
        className="table-toolbar-container"
        data-testid={'transactions-table-toolbar'}
      >
        <div className="table-toolbar-group-left">
          <div className="table-toolbar-column">
            <div className="btn-toolbar table-btn-toolbar">
              <div data-testid={'transactions-table-start-date-picker'}>
                <label>
                  <CustomFormattedMessage id={'common.startDate'} />
                </label>
                <DatePicker
                  closeOnSelect
                  timeFormat={false}
                  value={from}
                  minWidth={130}
                  onChange={handleDatePicked(setFrom)}
                  dateFormat={'DD/MM/YYYY'}
                />
              </div>
            </div>
          </div>
          <div className="table-toolbar-column">
            <div className="btn-toolbar table-btn-toolbar">
              <div data-testid={'transactions-table-end-date-picker'}>
                <label>
                  <CustomFormattedMessage id={'common.endDate'} />
                </label>
                <DatePicker
                  aria-label={'end-date'}
                  closeOnSelect
                  timeFormat={false}
                  value={to}
                  minWidth={130}
                  onChange={handleDatePicked(setTo)}
                  dateFormat={'DD/MM/YYYY'}
                />
              </div>
            </div>
          </div>
          <div className="table-toolbar-column">
            <div className="btn-toolbar table-btn-toolbar">
              <div data-testid={'transactions-table-period-selector'}>
                <label>
                  <CustomFormattedMessage id={'common.period'} />
                </label>
                <Select
                  useClear
                  className={'width-200'}
                  id={'periodSelector'}
                  placeholder={
                    <CustomFormattedMessage id={'common.selectPeriod'} />
                  }
                  options={transactionFilterPeriods}
                  onChange={(option) => {
                    const value = option ? parsePeriod(option.id) : undefined;
                    dispatch(setPeriod(value));
                  }}
                  value={period ? [period.toString()] : []}
                />
              </div>
            </div>
          </div>
          <div className="table-toolbar-column">
            <div className="btn-toolbar table-btn-toolbar">
              <div data-testid="transactions-table-currency-filter">
                <label>
                  <CustomFormattedMessage id={'common.currency'} />
                </label>
                <Multiselect
                  className={'width-200'}
                  placeholder={intl.formatMessage({
                    id: 'transactions.transactionsTable.toolbar.currencyFilterPlaceholder',
                  })}
                  id={'currencySelector'}
                  options={currencyOptions}
                  onChange={(selected: string[]) => {
                    dispatch(setTransactionsCurrencies(selected));
                  }}
                />
              </div>
            </div>
          </div>
          {appConfiguration.iaaFeatures.enabled && (
            <div className="table-toolbar-column">
              <div className="btn-toolbar table-btn-toolbar">
                <Button
                  data-testid={'transactions-table-filter-button'}
                  bsStyle={'primary'}
                  onClick={() => dispatch(openFilterDialog())}
                  className={'padding-y-0'}
                >
                  <span className={'rioglyph-filter text-size-20'} />
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className="table-toolbar-group-right">
          {appConfiguration.iaaFeatures.enabled && <TableToolbarSearch />}
          {hasTransactionUpdate && (
            <div className="table-toolbar-column">
              <div className="btn-toolbar table-btn-toolbar">
                <Button
                  data-testid="transactions-table-refresh-button"
                  bsStyle={'primary'}
                  onClick={handleRefreshTransactions}
                >
                  <span className={'rioglyph rioglyph-refresh'} />
                  <CustomFormattedMessage
                    id={'transactions.button.refreshTransactions'}
                  />
                </Button>
              </div>
            </div>
          )}
          <div className="table-toolbar-column">
            <div className="btn-toolbar table-btn-toolbar">
              <Button
                bsStyle={'primary'}
                onClick={() => {
                  dispatch(resetTransactionsFilters());
                }}
              >
                <span className={'rioglyph rioglyph-revert'} />
                <CustomFormattedMessage
                  id={'transactions.transactionsTable.toolbar.resetFilters'}
                />
              </Button>
            </div>
          </div>
          <div className="table-toolbar-column">
            <div className="btn-toolbar table-btn-toolbar">
              <CsvExportButton />
            </div>
          </div>
        </div>
      </div>
    </TableToolbar>
  );
};
