import { CustomFormattedMessage } from '../../../../i18n/i18n';
import { FormattedAddress } from '../../../../components/FormattedAddress';
import { Poi } from '../../../../codegen/transactions';
import { SidebarRow } from '../../../../components/sidebar/SidebarRow';

type StationDetailsTableProps = {
  poi?: Poi;
  pumpNumber?: number;
};

export const StationDetailsTable = ({
  poi,
  pumpNumber,
}: StationDetailsTableProps) => {
  if (!poi) {
    return <></>;
  }

  return (
    <>
      <h6 className="padding-left-10 margin-top-20">
        <CustomFormattedMessage id="common.transaction.stationDetails" />
      </h6>
      <table
        className="table table-condensed"
        data-testid={'transactions-sidebar-station-details'}
      >
        <colgroup>
          <col style={{ width: 150 }} />
          <col />
        </colgroup>
        <tbody>
          <SidebarRow label="common.contact.name">{poi.name}</SidebarRow>
          {poi.brand && (
            <SidebarRow label="common.transaction.stationDetails.brand">
              {poi.brand}
            </SidebarRow>
          )}
          <SidebarRow label="common.contact.address">
            <FormattedAddress {...poi.address} />
          </SidebarRow>
          {pumpNumber && (
            <SidebarRow label="common.transaction.stationDetails.pumpNo">
              {pumpNumber}
            </SidebarRow>
          )}
        </tbody>
      </table>
    </>
  );
};
